.defaultSubNav{
    grid-row: 1/2;
    margin-top: 90px;
    place-self: center;
    z-index: 1;
    display: grid;
    grid-auto-flow: column;
}
.defaultSubNav h3{
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 14px;
    margin: 0px 10px;
    cursor: pointer;
    display: grid;
    place-items: center;
}
@media screen and (max-width:550px) {
    .defaultSubNav h3{
        font-size: 10px;
    }
}