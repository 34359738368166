.TimeLineSection{
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    position: relative;
    place-items: start;
    touch-action: none;
    background-color: #242424;
    background-position: 0% 100%;
    background-attachment: fixed;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    overflow: hidden;
}
.timelineSection{
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    width: 100%;
    height: max-content;
    display: grid;
    grid-column: 1/-1;
    padding-left: 10%;
}
.timelineSlogan{
    position: absolute;
    bottom: calc(200px + 1vh);
    line-height: .9em;
    letter-spacing: 2px;
    left: 12vw;
    z-index: 10000;
    font-size: 3.5vw;
    font-family: var(--font-heavy-italic);
    width: 1px;
    word-break: keep-all;
}
.timelineSection .sectionHeader{
    grid-row: 1/2;
}
.timelineSection #timelineLine{
    width: 12px;
    place-self: center;
    height: calc(100vh + 0px);
    border-radius: 6px;
    grid-column: 1/-1;
    grid-row: 1/-1;
    transition: 0ms;
    z-index: 10000;
}
.timelineSection #events{
    width: 100%;
    place-self: end;
    height: max-content;
    background-color: transparent;
    display: grid;
    border-radius: 6px;
    grid-column: 1/-1;
    grid-row: 2/-1;
    grid-template-rows: 2fr 2fr 2fr 1fr;
    height: calc(100vh);
}
.timelineSection #events a{
    place-items: center;
    height: 0px;
    opacity: 0;
}
.timelineSection #events .event .timelineCover{
    position: absolute;
    width: 100vw;
    height: 100vh;
    grid-column: 1/-1;
    grid-row: 1/-1;
    top: 0;
    left: 0;
    filter: brightness(25%);
    opacity: 0;
    transition: 1000ms;
}
.timelineSection #events .event .timelineCover.cut{
    object-fit: cover;
}
.timelineSection #events .event .timelineCover.stretched{
    object-fit: auto;
}
.timelineSection #events .event .eventStatic{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 10px 1fr;
    place-items: center;
    grid-column: 1/-1;
    grid-row: 1/-1;
    opacity: 1;
    transition: 500ms;
}
.timelineSection #events  .event .eventDetails{
    mask-image: none;
    width: 200px;
    height: 120px;
    display: grid;
    place-items: center start;
    padding: 10px;
    margin: 25px;
    font-size: 20px;
    word-break: keep-all;
    border-radius: 12px;
    z-index: 1;
    place-self: start;
    font-family: var(--font-bold-italic);
}
.timelineSection #events .event .eventDetails .eventCover{
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: #000000;
}
.timelineSection #events .event .eventDetails h4{
    font-size: 12px;
    color: #b6b6b5;
}
.timelineSection #events .event #point{
    width: 1vw;
    height: 1vw;
    background-color: var(--active-color);
    border-radius: 50%;
    transition: 600ms;
    z-index: 10;
}
.timelineSection #events .event .pointImg{
    mask-image: none;
    width: 60px;
    height: auto;
    place-self: center end;
    margin: 0 15px 0 0;
    z-index: 10;
    transition: 600ms;
}
.timelineSection #events .event.active .pointImg{
    width: 80px;
}
.timelineSection #events .event.active .timelineCover{
    opacity: 1;
}

.timelineSection #events a.previous,
.timelineSection #events a.current,
.timelineSection #events a.next,
.timelineSection #events a.last,
.timelineSection #events a.currentToPrevious,
.timelineSection #events a.nextToCurrent,
.timelineSection #events a.currentToNext,
.timelineSection #events a.previousToCurrent,
.timelineSection #events a.lastToNext,
.timelineSection #events a.nextToLast,
.timelineSection #events a.toLast,
.timelineSection #events a.toPrevious{
    grid-column: 1/-1;
    display: grid;
    height: 100%;
    opacity: 1;
}
.timelineSection #events a.last{
    transition: 500ms;
}

.timelineSection #events a.previous{ grid-row: 1/2 }
.timelineSection #events a.current{ grid-row: 2/3 }
.timelineSection #events a.next{ grid-row: 3/4 }
.timelineSection #events a.last{ grid-row: 4/-1 }

.timelineSection #events a.currentToPrevious{ animation: currentToPrevious 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.currentToPrevious .eventStatic{ animation: currentDetailsToPrevious 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.nextToCurrent{ animation: nextToCurrent 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.nextToCurrent .eventStatic{ animation: nextDetailsToCurrent 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.currentToNext{ animation: currentToNext 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.currentToNext .eventStatic{ animation: currentDetailsToNext 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.previousToCurrent{ animation: previousToCurrent 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.previousToCurrent .eventStatic{ animation: previousDetailsToCurrent 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.lastToNext{ animation: lastToNext 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.lastToNext .eventStatic{ animation: lastDetailsToNext 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.nextToLast{ animation: nextToLast 500ms ease-in-out 0ms 1 forwards alternate }

.timelineSection #events a.toLast{ grid-row: 4/-1 }
.timelineSection #events a.toLast .eventStatic{ animation: toLastDetails 500ms ease-in-out 0ms 1 forwards alternate }
.timelineSection #events a.toPrevious{ grid-row: 1/2 }
.timelineSection #events a.toPrevious .eventStatic{ animation: toPreviousDetails 500ms ease-in-out 0ms 1 forwards alternate }

.timelineSection #events a.last .eventDetails,
.timelineSection #events a.toLast .eventDetails,
.timelineSection #events a.nextToLast .eventDetails{
    mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,.7)), to(rgba(0,0,0,0)));
}
.timelineSection #events a.last .pointImg,
.timelineSection #events a.toLast .pointImg,
.timelineSection #events a.nextToLast .pointImg{
    mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,.7)), to(rgba(0,0,0,0)));
}

@keyframes currentToPrevious {
    0%{ grid-row: 2/3 }
    99%{ grid-row: 2/3 }
    100%{ grid-row: 1/2 }
}
@keyframes currentDetailsToPrevious {
    0%{ margin-top: 0vh }
    99%{ margin-top: -37vh }
    100%{ margin-top: 0vh }
}
@keyframes nextToCurrent {
    0%{ grid-row: 3/4 }
    99%{ grid-row: 3/4 }
    100%{ grid-row: 2/3 }
}
@keyframes nextDetailsToCurrent {
    0%{ margin-top: 0vh }
    99%{ margin-top: -35vh }
    100%{ margin-top: 0vh }
}
@keyframes currentToNext {
    0%{ grid-row: 2/3 }
    99%{ grid-row: 2/3 }
    100%{ grid-row: 3/4 }
}
@keyframes currentDetailsToNext {
    0%{ margin-top: 0vh }
    99%{ margin-top: 2vh }
    100%{ margin-top: 0vh }
}
@keyframes previousToCurrent {
    0%{ grid-row: 1/2 }
    99%{ grid-row: 1/2 }
    100%{ grid-row: 2/3 }
}
@keyframes previousDetailsToCurrent {
    0%{ margin-top: 0vh }
    99%{ margin-top: 20vh }
    100%{ margin-top: 0vh }
}
@keyframes lastToNext {
    0%{ grid-row: 4/-1 }
    99%{ grid-row: 4/-1 }
    100%{ grid-row: 3/4 }
}
@keyframes lastDetailsToNext {
    0%{ margin-top: 0vh }
    99%{ margin-top: -34vh }
    100%{ margin-top: 0vh }
}
@keyframes nextToLast {
    0%{ grid-row: 3/4 }
    99%{ grid-row: 3/4 }
    100%{ grid-row: 4/-1 }
}
@keyframes toLastDetails {
    0%{ transform: translateY(23vh) }
    99%{ transform: translateY(5vh) }
    105%{ transform: translateY(5vh) }
}
@keyframes toPreviousDetails {
    0%{ transform: translateY(-23vh) }
    99%{ transform: translateY(-5vh) }
    105%{ transform: translateY(0vh) }
}

@media screen and (max-width:575px) {
    .timelineSlogan{
        font-size: 5vw;
    }
    .timelineSection #events .event.active .pointImg{
        width: 50px;
    }
    .timelineSection #events .event .pointImg{
        width: 30px;
    }
    .timelineSection #events  .event .eventDetails{
        width: 150px;
        height: 120px;
    }
}
@media screen and (max-width:425px) {
    .timelineSection #events  .event .eventDetails{
        width: 110px;
        height: 120px;
    }
}
