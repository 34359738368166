.applicationContainer{
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 100%;
    height: 100vh;
    place-items: center;
    display: grid;
    background-image: url('./../../comingSoon.png');
    grid-template-rows: 1fr min-content;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    place-items: end center;
}
.applicationContainer h2{
    font-size: 1.6vw;
    color: #fff;
    margin-bottom: 3vh;
}
.applicationContainer .applicationOptions{
    display: grid;
    grid-auto-flow: column;
    column-gap: 30px;
    margin-bottom: 200px;
}
.applicationContainer .applicationOptions button{
    background-color: var(--active-color);
    border: none;
    outline: none;
    place-self: center;
    height: 50px;
    z-index: 10;
    width: 250px;
    border-radius: 12px;
    text-align: center;
    font-size: 20px;
    color: var(--font-color);
    font-family: var(--font-medium);
    font-weight: 800;
    cursor: pointer;
    transition: 0.6s;
}
.applicationContainer .applicationOptions button a{
    text-decoration: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}
.aboveAlll{
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: absolute;
    display: grid;
    place-items: center start;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}

@media screen and (max-width: 500px) {
    .applicationOptions{
        row-gap: 30px;
        grid-auto-flow: row !important;
        margin-bottom: 10vh !important;
    }
    .applicationContainer h2{
        text-align: center;
        max-width: 90vw;
        font-size: 30px;
    }
}