/* blog header section */

.blog-head{
    font-family: var(--font-heavy-italic);
    font-size: calc(100vw / 22);
    margin-top: 50px;
    word-break: keep-all;
    margin-left: 12vw;
    max-width: 78vw;
}

/* blog content section */

.blog-content{
    width: 78vw;
    padding: 0 0 0 12vw;
    margin-top: 15px;
}

.blog-content div{
    row-gap: 30px;
}

.blog-content-header{
    font-size: 50px;
    word-break: keep-all;
}
.blog-content-image{
    width: 100%;
    border-radius: 8px;
}
.blog-content-paragraph{
    word-break: keep-all;
    font-size: 1.466em;
    text-align: justify !important;
    width: 78vw !important;
    max-width: 78vw;
}

/* blog see more section */

.blog-rm-head{
    font-family: var(--font-heavy-italic);
    font-size: 50px;
    margin-top: 50px;
    margin-left: 12vw;
}
.blog-read-more .blogs-collection{
    width: 100vw;
    display: grid;
    justify-content: center;
    grid-auto-flow: row;
    grid-template-columns: auto auto;
    margin-bottom: 25px;
}
.blog-read-more .blog{
    text-decoration: none;
    display: grid;
    max-width: min-content;
}
.blog-read-more .blog img{
    grid-column: 1/-1;
    grid-row: 1/-1;
    width: 37vw;
    height: calc(20vw);
    border-radius: 12px;
}
.blog-read-more .blog .inCardText{
    grid-column: 1/-1;
    grid-row: 1/-1;
    margin: 0px 10px 30px 35px;
    text-decoration: none;
    display: grid;
    place-self: end start;
    grid-template-rows: auto auto;
    place-items: end start;
    width: calc(100% - 70px);
}
.blog-read-more .blog .inCardText h4{
    text-decoration: none;
    font-family: var(--font-heavy-italic);
    font-size: calc(100vw / 36);
    color: var(--font-color);
    word-break: keep-all;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.blog-read-more .blog .inCardText h5{
    font-size: 14px;
    text-decoration: none;
    color: #e6e6e6e3;
    font-weight: 100;
}
@media screen and (max-width:900px){
    .blog-read-more .blogs-collection{
        grid-template-columns: auto;
    }
    .blog-read-more .blogs-collection .blog img{
        width: 70vw;
        height: calc(calc(70vw * 9)/16);
    }
}
@media (max-width:550px){
    .blog-head{
        font-size: calc(100vw / 15);
    }
    .blog-content-header{
        font-size: calc(100vw / 18);
    }
    .blog-content-paragraph{
        text-align: left !important;
        font-size: 20px;
    }
    .blog-rm-head{
        font-size: calc(100vw / 16);
    }
}