.blogContainer .aboveAlll{
    position: fixed;
}
.blogContainer{
    grid-row: 1/-1;
    width: 100%;
    height: 100vh;
    place-self: start center;
    grid-template-columns: 1fr;
    place-items: center start;
    grid-auto-flow: row;
    display: grid;
    position: absolute;
    place-items: start;
    overflow-x: hidden;
    touch-action: pan-y;
    background-color: #1d1d1b;
    padding-top: 100px;
}
.blogContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 4px;
}
.blogContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.blogContainer #blog div{
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 100%;
    height: 100%;
    place-self: start center;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content 1fr;
    place-items: center;
    grid-auto-flow: column;
    display: grid;
}
.blogContainer #blog div img{
    grid-row: 1/2;
    grid-column: 1/-1;
    width: 78vw;
    max-width: 78vw;
    margin-top: 25px;
    place-self: center;
    max-height: auto;
}
.blogContainer #blog div .headers{
    width: 100%;
    height: max-content;
    grid-row: 2/3;
    grid-column: 1/-1;
    grid-auto-flow: column;
    grid-template-columns: max-content max-content 1fr;
    display: grid;
    place-items: center start;
}
.blogContainer #blog div .headers .title{
    margin: 50px 30px;
    margin-bottom: 0;
    font-size: 3em;
    word-break: break-all;
    max-width: calc(100vw - 150px);
    grid-column: 1/2;
    text-align: center;
}
.blogContainer #blog div .headers .date{
    margin: 50px 30px;
    font-size: 1.5em;
    margin-bottom: 0;
    color: rgb(145, 145, 145);
    grid-column: 3/-1;
    text-align: center;
}
.blogContainer #blog div .headers .author{
    margin: 50px 30px;
    font-size: 1.5em;
    margin-bottom: 0;
    color: rgb(182, 182, 182);
    grid-column: 2/3;
    text-align: center;
}
.blogContainer #blog div .content{
    grid-column: 1/-1;
    grid-row: 3/-1;
    width: 100%;
    margin-top: 50px;
    height: max-content;
}
.blogContainer #blog div .content p{
    width: 90vw;
    margin: 0 5vw;
    word-break: keep-all;
    font-size: 20px;
    max-height: 50vh;
    line-height: 40px;
    margin-top: 60px;
}
.blogContainer .albumSection{
    width: 78vw;
    margin-left: 12vw;
}
.blogContainer .blogAlbumSectionHeader{
    margin: 0;
}
.blogContainer .albumGroup{
    margin: 0;
}
.blogContainer .footer{
    margin-top: 20vh;
    margin-bottom: 20vh;
    display: grid;
    place-items: center;
    place-self: center;
    text-align: center;
}
@media screen and (max-width: 500px) {
    .blogContainer #blog div img{
        width: 90%;
    }
}
@media screen and (max-width: 900px) {
    .blogContainer #blog div .headers{
        grid-auto-flow: row;
        grid-template-rows: max-content max-content max-content;
        grid-template-columns: 1fr;
    }
    .blogContainer #blog div .headers .title{
        grid-row: 1/2;
        grid-column: 1/-1;
    }
    .blogContainer #blog div .headers .date{
        grid-row: 3/-1;
        grid-column: 1/-1;
    }
    .blogContainer #blog div .headers .author{
        grid-row: 2/3;
        grid-column: 1/-1;
    }
}