.customContainer{
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    place-items: start;
    grid-auto-flow: row;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content,min-content);
    row-gap: 0px;
    background-color: #12120e;
}
.customContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 10px;
}
.customContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.customContainer img.cover{
    width: 100%;
    height: calc(100vh);
    place-self: start center;
    grid-column: 1/-1;
    grid-row: 1/-1;
    min-height: 250px;
    filter:brightness(80%);
}
.customContainer .coverOverlay{
    width: 100%;
    height: calc(100vh);
    place-self: start center;
    grid-column: 1/-1;
    grid-row: 1/-1;
    min-height: 250px;
    filter:brightness(80%);
    z-index: 1;
    background: linear-gradient(0deg, rgb(18 18 14 / 81%) 0%, rgba(255,255,255,0) 30%);
}
.sectionsWrapper{
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    height: min-content;
    z-index: 10;
}
.aboveAlll{
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: absolute;
    display: grid;
    place-items: center start;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}
.aboutSubNav{
    place-self: start center;
    z-index: 1;
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
    margin-top: 2vh;
}
.aboutSubNav h3{
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 14px;
    margin: 0px 10px;
    cursor: pointer;
    display: grid;
    place-items: center;
}
.welcomeSection{
    text-align: left;
    backdrop-filter: blur(1px);
    height: 100vh;
    display: grid;
    grid-template-rows: min-content min-content 1fr;
}
.coverText{
    place-self: start;
    text-align: left;
    backdrop-filter: blur(1px);
    font-weight: 900;
    font-size: 60px;
    font-family: var(--font-heavy-italic);
    margin-top: calc(90px + 11vh);
    margin-left: 12vw;
}
.section{
    max-height: max-content;
    z-index: 10;
    /* transform: translateY(calc(-100vh + 222px)); */
}
.aboutSectionHeader{
    font-weight: 900;
    font-size: 70px;
    transform: translateX(12vw);
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 90px;
    font-family: var(--font-heavy-italic);
    z-index: 10;
}
.aboutSection{
    margin-top: -12vh;
}
.aboutSection .aboutSectionHeader{
    margin-top: 0px;
    padding-bottom: 0px;
}
.aboutSection .teamMembers{
    width: 78vw;
    display: grid;
    justify-content: center;
    margin-left: 11vw;
    place-items: center;
    grid-auto-flow: column;
    column-gap: 6vw;
}
.aboutSection .teamMembers img{
    width: 28vw;
    transition: 1s;
    max-width: 40vw;
    z-index: 10;
}
.aboutSection p{
    word-break: keep-all;
    width: 78vw;
    max-width: 78vw;
    transition: 1s;
    transform: translate(12vw,0px);
    font-size: 1.36em;
    text-align: justify;
}
.visionSection p{
    word-break: keep-all;
    width: 78vw;
    max-width: 78vw;
    transition: 1s;
    transform: translate(12vw,0px);
    font-size: 1.36em;
    line-height: 30px;
    text-align: justify;
}
.visionSection{
    display: grid;
    width: 100vw;
}
.visionSection .goals{
    place-self: center;
    width: max-content;
    place-items: center;
    display: grid;
    grid-auto-flow: column;
    padding-top: 50px;
    padding-bottom: 50px;
}
.visionSection .goals .goal{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    place-items: center;
    transition: 1s;
    font-family: var(--font-bold-italic);
}
.visionSection .goals .goal img{
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 60px;
    transition: 1s;
}
.progressCircle{
    rotate: 90deg;
    grid-column: 1/-1;
    grid-row: 1/2;
}
.board-member{
    margin-top: 70px;
    display: grid;
    height: max-content;
    width: 70vw;
    place-self: center;
    place-items: start center;
    grid-template-columns: 4fr 9fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: column;
    place-items: center;
    overflow: hidden;
}
.boardSection{
    place-items: center;
    display: grid;
}
.boardSection .aboutSectionHeader{
    place-self: start;
    width: 1px;
    word-break: keep-all;
}
.board-member h3{
    font-size: 26px;
    text-align: center;
    font-family: var(--font-bold-italic);
    grid-column: 2/-1;
    text-align: start;
    opacity: 0;
    width: 100%;
    transform: translateX(100%);
    transition: 1s;
}
.board-member .summary{
    word-break: keep-all;
    font-size: 1.36em;
    margin-top: 10px;
    place-self: start;
    grid-column: 2/-1;
    text-align: justify;
    opacity: 0;
    width: 100%;
    transform: translateX(100%);
    transition: 1s;
}
.board-member div{
    display: grid;
    grid-column: 1/2;
    grid-row: 1/-1;
    grid-auto-flow: row;
    place-items: start;
    place-self: start;
    opacity: 0;
    transform: translateX(100%);
    transition: 1s;
}
.board-member div .image{
    height: auto;
    width: 65%;
    /* border-radius: 50%; */
}
/* .board-member div .name{
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    font-family: var(--font-bold-italic);
}
.board-member div .job{
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    color: #c6c6c6;
} */
.customContainer .footer{
    display: grid;
    width: 100vw;
    height: max-content;
    place-items: center;
    text-align: center;
    height: 40vh;
    grid-template-rows: max-content max-content max-content;
    margin-top: 10vh;
}
.footer img{
    width: 70vw;
    max-width: 300px;
}
.footer h2{
    font-family: var(--font-heavy-italic);
    font-size: 30px;
    max-width: 70vw;
}
.footer .media-in-footer{
    width: min-content;
    place-self: center;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
}
.footer .media-in-footer .media{
    width: 30px;
    padding: 8px;
}
.footer .media-in-footer .media img{
    width: 30px;
}

/* animations effect */

.aboutSection .teamMembers.waiting img{
    opacity: 0;
}
.aboutSection .teamMembers.aniamted img{
    opacity: 1;
}
.aboutSection p.waiting{
    opacity: 0;
}
.aboutSection p.aniamted{
    opacity: 1;
}
.visionSection p.waiting{
    opacity: 0;
}
.visionSection .goal h4.waiting{
    opacity: 0;
}
.visionSection .goals .goal img.waiting{
    transform: translateY(80px);
    opacity: 0;
}
.visionSection .goal h4.aniamted{
    opacity: 1;
}
.visionSection .goal img.aniamted{
    transform: translateY(0px);
    opacity: 1;
}
.board-member.animated .summary,.board-member.animated h3{
    animation-name: animation2;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}
.board-member.animated div{
    animation-name: animation3;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}
.board.rtl .board-member.animated .summary{
    animation-name: animation3 !important;
}
.board.rtl .board-member.animated div{
    animation-name: animation2 !important;
}

/* animations */

@keyframes animation2 {
    0%   {
        opacity: 0;
        transform: translateX(100%);
        transition: 1s;
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
        transition: 1s;
    }
}
@keyframes animation3 {
    0%   {
        opacity: 0;
        transform: translateX(-100%);
        transition: 1s;
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
        transition: 1s;
    }
}

/* media query */

@media screen and (max-width: 1050px) {
    .board-member{
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content;
        grid-auto-flow: row;
        place-items: start center;
    }
    .board-member .summary{
        grid-column: 1/-1;
        grid-row: 3/-1;
        margin-top: 15px;
        text-align: center;
        width: 80%;
        max-height: calc(100vh);
        place-self: center;
    }
    .board-member h3{
        grid-column: 1/-1;
        grid-row: 2/3;
        width: 80%;
        text-align: center;
        margin-top: 30px;
    }
    .board-member div{
        grid-column: 1/-1;
        grid-row: 1/2;
        place-items: center;
    }
}
@media screen and (max-width:950px) {
    .aboutSectionHeader{
        font-size: 40px;
    }
}
@media screen and (max-width:650px) {
    .aboutSection .teamMembers{
        /* grid-auto-flow: row; */
        margin-bottom: 20px;
    }
    .aboutSection .teamMembers img{
        width: 40vw;
        transition: 1s;
        max-width: 40vw;
        z-index: 10;
    }
    p{
        font-size: 18px !important;
    }
    .visionSection .goals{
        padding: 10px 0px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .visionSection .goals .goal{
        scale: .6;
    }
    .goals .g1{
        grid-column: 1/3;
    }
    .goals .g2{
        grid-column: 3/5;
    }
    .goals .g3{
        grid-column: 5/-1;
    }
    .goals .g4{
        grid-column: 1/4;
    }
    .goals .g5{
        grid-column: 4/-1;
    }
}
@media screen and (max-width:550px) {
    .aboutSubNav h3{
        margin: 0 5px;
        font-size: 10px;
    }
    .coverText{
        font-size: 20px;
        word-break: keep-all;
        max-width: 90vw;
    }
    .aboutSectionHeader{
        font-size: 30px;
    }
    .board-member .summary{
        width: 70%;
    }
    .timelineSection #events .eventsSection .event .eventDetails{
        margin: 10px;
        padding: 0;
        width: max-content;
        max-width: 40vw;
        word-break: break-all;
    }
}
@media (max-width:550px){
    .aboutSection p, .visionSection p{
        text-align: left;
    }
    .board-member .summary{
        text-align: left;
    }
}