.communityContainer{
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    place-items: start;
    grid-auto-flow: row;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content,min-content);
    row-gap: 0px;
    background-color: #0e0e0d;
}
.communityContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 8px;
}
.communityContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.communitySubNav{
    grid-row: 1/2;
    margin-top: 140px;
    place-self: center;
    z-index: 1;
    display: grid;
    grid-auto-flow: column;
}
.communitySubNav h3{
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 14px;
    margin: 0px 10px;
    cursor: pointer;
    display: grid;
    place-items: center;
}
.communitySectionList{
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: absolute;
    height: fit-content;
    width: fit-content;
    display: grid;
    place-items: end;
    grid-template-rows: repeat(3,1fr);
    bottom: 0;
    right: 0;
    z-index: 10000;
    margin: 150px 25px;
    min-width: 50px;
    height: 125px;
    z-index: 10;
}
.communitySectionList:hover > h2{
    font-size: 16px;
    width: fit-content;
    background-color: transparent;
    transition: font-size 0.8s, color 0.8s;
}
.communitySectionList:hover > h2.active{
    font-size: 24px;
    padding: 8px;
    width: fit-content;
    background-color: transparent;
    transition: font-size 0.8s, color 0.8s;
}
.communitySectionList h2{
    font-size: 0px;
    cursor: pointer;
    transition: font-size 0.8s, color 0.8s;
    direction: rtl;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    line-height: 30px;
    margin-top: 15px;
}
.communitySectionList h2.active{
    background-color: var(--active-color);
    color: var(--active-color);
}
.communitySectionHeader{
    font-weight: 900;
    font-size: 50px;
    transform: translateX(12vw);
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 90px;
    font-family: var(--font-heavy-italic);
}
.eventSection .communitySectionHeader{
    margin-top: 150px;
    margin-bottom: 20px;
}
.eventSection .event{
    width: 78vw;
    display: grid;
    place-items: center;
    margin-left: 12vw;
}
.eventSection .sectionExpand{
    width: 120px;
    height: 50px;
    background-color: transparent;
    color: #c1c1c1;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 16px;
    transform: translateX(12vw);
    font-family: var(--font-medium);
}
.eventSection .event img{
    width: 78vw;
    height: calc(78vw / 8);
    border-radius: 12px;
    margin: 10px 0px;
}
.blogSection .blogCollection{
    width: 78vw;
    display: grid;
    justify-content: space-between;
    grid-auto-flow: row;
    grid-template-columns: auto auto;
    margin-left: 12vw;
}
.blogSection .sectionExpand{
    width: 120px;
    height: 50px;
    background-color: transparent;
    color: #c1c1c1;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 16px;
    transform: translateX(12vw);
    font-family: var(--font-medium);
}
.blogSection .blog{
    text-decoration: none;
    display: grid;
    margin-top: 20px;
    max-width: min-content;
}
.blogSection .blog img{
    width: 37vw;
    aspect-ratio: 16/9;
    border-radius: 12px;
    grid-column: 1/-1;
    grid-row: 1/-1;
}
.blogSection .blog .inCardText{
    grid-column: 1/-1;
    grid-row: 1/-1;
    margin: 0px 10px 30px 35px;
    text-decoration: none;
    display: grid;
    place-self: end start;
    grid-template-rows: auto auto;
    place-items: end start;
    width: calc(100% - 70px);
}
.blogSection .blog .inCardText h4{
    text-decoration: none;
    font-family: var(--font-heavy-italic);
    font-size: calc(100vw / 36);
    color: var(--font-color);
    word-break: keep-all;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.blogSection .blog .inCardText h5{
    font-size: 14px;
    text-decoration: none;
    color: #e6e6e6e3;
    font-weight: 100;
    z-index: 100000;
}
.albumSection{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    width: 100%;
    height: 100%;
}
.albumsCollection{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
}
.albumSection .albumExpand{
    width: 120px;
    height: 50px;
    background-color: transparent;
    color: #c1c1c1;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 16px;
    transform: translateX(12vw);
}
.albumSection .albumInstance{
    grid-auto-flow: row;
    display: grid;
    width: max-content;
}
.albumSection .albumInstance .albumName{
    font-family: var(--font-medium);
    font-size: 35px;
    transform: translateX(12vw);
    margin-top: 25px;
    word-break: break-all;
    max-width: 78vw;
}
.communityContainer .albumSection .albumGroup{
    width: 78vw;
    transform: translateX(12vw);
    margin: 0;
}
.albumSection .albumGroup div{
    width: calc(100% - 16px);
    height: calc(calc(calc(26vw * 9)/16) + 24px);
    display: grid;
    place-items: center;
    background-color: #2f2f2f6b;
    /* padding: 8px; */
    border-radius: 8px;
    cursor: pointer;
}

.albumSection .albumGroup * img{
    object-fit: cover;
    width: 100% !important;
}

.albumSection .albumGroup a{
    width: calc(100% - 16px);
    height: calc(calc(calc(26vw * 9)/16) + 24px);
    display: grid;
    place-items: center;
    background-color: #2f2f2f6b;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    margin-top: 16px;
    text-decoration: none;
    color: var(--active-color);
}

.albumSection .albumGroup a h4{
    height: 30px;
    margin-bottom: -16px;
}

.pop-up-cover{
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
}
.pop-up-star{
    max-width: 80vw;
    top: 0;
    left: 0;
    grid-row: 1/-1;
    grid-column: 1/-1;
    max-height: calc(100vh - 200px);
    z-index: 1000;
}
.pop-up-closer{
    width: 35px;
    height: 35px;
    position: absolute;
    top: 120px;
    left: 50px;
    z-index: 10000;
    cursor: pointer;
}
@media screen and (min-width:950px) {
    .albumSection .albumGroup.default div:nth-child(4){
        grid-column: 3/-1;
        grid-row: 2/-1;
    }
    .albumSection .albumGroup.default a:nth-child(4){
        grid-column: 3/-1;
        grid-row: 2/-1;
    }
}
.albumSection .albumGroup div:first-child{
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 100%;
    width: calc(100% - 16px);
    margin-bottom: 8px;
    height: calc(calc(calc(78vw * 9)/16) + 24px);
}
.albumSection .albumGroup a:first-child{
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 100%;
    width: calc(100% - 16px);
    margin-bottom: 8px;
    height: calc(calc(calc(78vw * 9)/16) + 24px);
}
.albumSection .albumGroup h5.sectionExpand{
    width: calc(100% - 16px);
    height: calc(calc(calc(26vw * 9)/16) + 24px);
    display: grid;
    place-items: center;
    background-color: #060606b8;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    /* margin-bottom: 8px; */
    margin-top: 2px;
    grid-column: 3/-1;
    grid-row: 2/-1;
    font-family: var(--font-medium);
    z-index: 100000;
}
.albumSection .albumGroup div img{
    margin: -8px;
    width: auto;
    max-width: 100%;
    height: inherit;
    max-height: calc(100% - 8px);
}
.albumSection .albumGroup a iframe{
    margin: -8px;
    width: 100%;
    margin-top: 0px;
    aspect-ratio: 16/9;
}

.videosSection .videosGroup{
    width: 78vw;
    transform: translateX(12vw);
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
.videosSection .videosGroup a{
    width: calc(100% - 16px);
    height: calc(calc(calc(26vw * 9)/16) + 24px);
    display: grid;
    place-items: center;
    background-color: #2f2f2f6b;
    padding: 8px;
    border-radius: 8px;
    margin-top: 16px;
    text-decoration: none;
    color: var(--active-color);
    cursor: default;
}
.videosSection .videosGroup a iframe{
    margin: -8px;
    width: 100%;
    place-self: center;
    border: 0;
    aspect-ratio: 16/9;
}
.videosSection .videosGroup a .video-details{
    display: grid;
    place-items: center start;
    grid-auto-flow: column;
    width: 100%;
    margin-bottom: -16px;
    grid-template-columns: 1fr 35px;
}
.videosSection .videosGroup a .video-details img{
    margin-bottom: 4px;
    cursor: pointer;
}
.videosSection .videosGroup a video{
    margin: -8px;
    height: inherit;
    max-height: 100%;
    place-self: center;
}
.videosSection .videosGroup a h4{
    text-align: start;
    height: 30px;
    width: 100%;
    padding-right: 5px;
    word-break: keep-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.videosSection .sectionExpand{
    width: 120px;
    height: 50px;
    background-color: transparent;
    color: #c1c1c1;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 18px;
    transform: translateX(12vw);
    place-self: start;
    font-family: var(--font-medium);
}
.communityContainer .footer{
    display: grid;
    width: 100vw;
    height: max-content;
    place-items: center;
    text-align: center;
    height: 50vh;
    grid-template-rows: max-content max-content max-content;
    margin-top: 20vh;
}
.footer img{
    width: 70vw;
    max-width: 300px;
}
.footer h2{
    font-family: var(--font-heavy-italic);
    font-size: 30px;
    max-width: 70vw;
}
.footer .media-in-footer{
    width: min-content;
    place-self: center;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
}
.footer .media-in-footer .media{
    width: 30px;
    padding: 8px;
}
.footer .media-in-footer .media img{
    width: 30px;
}
@media screen and (max-width:1050px) {
    .videosSection .videosGroup{
        grid-template-columns: 1fr 1fr;
    }
    .videosSection .videosGroup a{
        height: calc(calc(calc(36vw * 9)/16) + 24px);
    }
}
@media screen and (max-width:950px) {
    .communitySectionHeader{
        font-size: 40px;
    }
    .albumSection .albumGroup{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .albumSection .albumGroup div{
        height: calc(calc(calc(36vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup div:first-child{
        grid-row: 1/2;
        grid-column: 1/2;
        height: calc(calc(calc(36vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup div:nth-child(4){
        grid-column: 2/-1;
        grid-row: 2/-1;
    }
    .albumSection .albumGroup a{
        height: calc(calc(calc(36vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup a:first-child{
        grid-row: 1/2;
        grid-column: 1/2;
        height: calc(calc(calc(36vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup a:nth-child(4){
        grid-column: 2/-1;
        grid-row: 2/-1;
    }
    .albumSection .albumGroup h5.sectionExpand{
        height: calc(calc(calc(36vw * 9)/16) + 24px);
        grid-column: 2/-1;
        grid-row: 2/-1;
        z-index: 100000;
    }
}
@media screen and (max-width:900px){
    .blogSection .blogCollection{
        grid-template-columns: auto;
    }
    .blogSection .blogCollection .blog img{
        width: 70vw;
        height: calc(calc(70vw * 9)/16);
    }
}
@media screen and (max-width:700px) {
    .eventSection .event img{
        height: calc(calc(70vw * 9)/16);
    }
    .videosSection .videosGroup{
        grid-template-columns: 1fr;
    }
    .videosSection .videosGroup a{
        height: calc(calc(calc(78vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    .albumSection .albumGroup div{
        grid-column: 1/-1;
        height: calc(calc(calc(78vw * 9)/16) + 24px);
        margin: 8px 0px;
    }
    .albumSection .albumGroup div:first-child{
        grid-column: 1/-1;
        height: calc(calc(calc(78vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup div:nth-child(4){
        grid-column: 1/-1;
        grid-row: 4/-1;
    }
    .albumSection .albumGroup a{
        grid-column: 1/-1;
        height: calc(calc(calc(78vw * 9)/16) + 24px);
        margin: 8px 0px;
    }
    .albumSection .albumGroup a:first-child{
        grid-column: 1/-1;
        height: calc(calc(calc(78vw * 9)/16) + 24px);
    }
    .albumSection .albumGroup a:nth-child(4){
        grid-column: 1/-1;
        grid-row: 4/-1;
    }
    .albumSection .albumGroup h5.sectionExpand{
        grid-column: 1/-1;
        grid-row: 4/-1;
        transform: translateY(calc(100% + 19px));
        height: calc(calc(calc(78vw * 9)/16) + 24px);
        z-index: 100000;
    }
    .albumSection .albumGroup h5{
        grid-column: 1/-1;
        grid-row: 4/-1;
        height: calc(calc(calc(78vw * 9)/16) + 24px);
        margin: 8px 0px;
        z-index: 100000;
    }
}
@media screen and (max-width:550px) {
    .communitySubNav h3{
        font-size: 10px;
    }
    .blogSection .blog .inCardText h4{
        font-size: 25px;
    }
    .coverText{
        font-size: 20px;
        word-break: keep-all;
        max-width: 90vw;
    }
    .communitySectionHeader{
        font-size: 30px;
    }
    .board-member .summary{
        width: 70%;
    }
}