@font-face {
    font-family: anLight;
    src: url('../fonts/avenir-next/light.ttf');
}
@font-face {
    font-family: anMedium;
    src: url('../fonts/avenir-next/medium.ttf');
}
@font-face {
    font-family: anDemi;
    src: url('../fonts/avenir-next/demi.otf');
}
@font-face {
    font-family: anBold;
    src: url('../fonts/avenir-next/bold.ttf');
}
@font-face {
    font-family: anBoldItalic;
    src: url('../fonts/avenir-next/bold-italic.ttf');
}
@font-face {
    font-family: anHeavyItalic;
    src: url('../fonts/avenir-next/heavy-italic.ttf');
}
@font-face {
    font-family: ancBoldItalic;
    src: url('../fonts/avenir-next/condensed-bold-italic.ttc');
}
@font-face {
    font-family: ancHeavyItalic;
    src: url('../fonts/avenir-next//condensed-heavy-italic.otf');
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    -webkit-user-drag: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}
*::first-letter{
    text-transform: capitalize;
}
.aboveAlll{
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: absolute;
    height: min-content;
    width: 0px;
    height: 0px;
    display: grid;
    place-items: center start;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}
*::-webkit-media-controls-panel {
    display: none!important;
    appearance: none;
    opacity: 0;
}
*::-webkit-media-controls-play-button {
    display: none!important;
    appearance: none;
    opacity: 0;
}
*::-webkit-media-controls-start-playback-button {
    display: none!important;
    appearance: none;
    opacity: 0;
}
*::-webkit-media-controls {
    display: none!important;
    appearance: none;
    opacity: 0;
}

:root{
    --active-color: #44D62C;
    --active-color-keen: #44D62C;
    --background-color: #3D3935;
    --dim-grey: #696969;
    --font-color: #f2f2f2;
    --nav-size: 18px;
    --normal-size: 22px;
    --font-light: anLight;
    --font-medium: anMedium;
    --font-demi: anDemi;
    --font-bold: anBold;
    --font-bold-italic: anBoldItalic;
    --font-heavy-italic: anHeavyItalic;
    --font-condensed-bold-italic: ancBoldItalic;
    --font-condensed-heavy-italic: ancHeavyItalic;
}

html,body,#root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
html{
    background-color: var(--background-color);
    overflow: hidden;
}
body{
    font-family: var(--font-light);
    font-display: swap;
    position: fixed;
    scroll-behavior: smooth;
    touch-action: double-tap-zoom;
    overscroll-behavior: contain;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.container{
    color: var(--font-color);
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: row;
    position: absolute;
    overflow: hidden;
    touch-action: none;
}
.app{
    grid-row: 1/-1;
    grid-column: 1/-1;
    z-index: 10000;
    width: 100%;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: 90px 1fr;
    grid-auto-flow: row;
}
.boldFamily{
    font-family: var(--font-bold-italic);
    font-size: 18px;
}
.app::-webkit-scrollbar{
    display: none;
    background-color: transparent;
    transition: 0.6s;
    width: 4px;
    margin: 4px;
}
.app::-webkit-scrollbar-button{
    height: 10px;
    background-color: transparent;
}
.app::-webkit-scrollbar-thumb:hover{
    background-color: var(--active-color);
    width: 4px;
    margin: 10px;
    border-radius: 10px;
    transition: 0.6s;
}

.fadeIn{
    opacity: 0;
    display: grid;
    animation: appear 500ms linear 200ms 1 forwards alternate;

}

.fadeOut{
    transition: 500ms;
    opacity: 0;
    display: grid;
    animation: disappear 1ms linear 500ms 1 forwards alternate;
}

.media-in-footer .media{
    z-index: 1;
}

.shadow-box{
    display: grid;
}

.shadow-box h4{
    filter: brightness(200%);
}

.shadow-box h5{
    filter: brightness(200%);
}

.shadow-box::before{
    grid-column: 1/-1;
    grid-row: 1/-1;
    width: 100%;
    height: 100%;
    position: relative;
    content: " ";
    pointer-events: none;
    border-radius: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, .2) 47%, rgba(0, 0, 0, 0) 50%);
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes disappear {
    0%{
        z-index: 1;
        display: grid;
    }
    90%{
        z-index: 1;
        display: grid;
    }
    100%{
        z-index: -1;
        display: none;
    }
}

@media only screen and (max-width: 600px) { 
    body { 
        background-image: url('./landingBackgroundMobile.svg');
    } 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
    body { 
        background-image: url('./landingBackgroundMobile.svg'); 
    } 
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
    body { 
        background-image: url('./landingBackgroundSml.svg'); 
    } 
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { 
    body { 
        background-image: url('./landingBackgroundMid.svg'); 
    } 
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
    body { 
        background-image: url('./landingBackgroundBig.svg') 
    } 
}