.blogAlbumSectionHeader{
    font-size: 50px;
    margin-left: 2vw;
    font-family: var(--font-heavy-italic);
    padding: 20px 0;
}
.galleryHeader{
    color: black;
    float: left;
    width: 100%;
    display: block;
    margin-left: 24vw;
    margin-bottom: -57vh;
    font-family: var(--font-heavy-italic);
    font-size: 4em;

}
.albumGroup{
    place-self: center;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    width: 78vw;
    margin-left: 2vw;
    grid-auto-flow: column;
    display: grid;
    grid-auto-flow: row;
}
.albumGroup.default{
    width: 100%;
    grid-template-rows: auto auto;
    transform: translateX(0px);
}
.albumGroup.default.academies{
    width: 78vw;
    grid-template-rows: auto auto;
    transform: translateX(0px);
}
.albumGroup div{
    width: calc(100% - 16px);
    height: calc(calc(calc(25vw * 9)/16) + 44px);
    display: grid;
    place-items: center;
    background-color: #2f2f2f6b;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    margin-top: 16px;
    text-decoration: none;
    color: var(--active-color);
    cursor: default;
}
.albumGroup div.light{
    background-color: #a4a4a46b;
}
.albumGroup a{
    width: calc(100% - 16px);
    height: calc(calc(calc(25vw * 9)/16) + 44px);
    display: grid;
    place-items: center;
    background-color: #2f2f2f6b;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    margin-top: 16px;
    text-decoration: none;
    color: var(--active-color);
    cursor: pointer;
}
.albumGroup a .video-details{
    display: grid;
    place-items: center start;
    grid-auto-flow: column;
    width: 100%;
    grid-template-columns: 1fr 35px;
    margin: 0;
    padding: 0;
    height: 30px;
    background-color: transparent;
    margin-bottom: -6px;
    cursor: default;
}
.albumGroup a .video-details img{
    cursor: pointer;
}
.albumGroup a.light{
    background-color: #a4a4a46b;
}
.albumGroup a h4{
    text-align: start;
    width: 100%;
    padding-right: 5px;
    word-break: keep-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.albumGroup * img{
    object-fit: cover;
    width: 100% !important;
}

@media screen and (min-width:950px) {
    .albumGroup.default *:nth-child(4){
        grid-column: 3/-1;
        grid-row: 2/-1;
    }
}
.albumGroup div:first-child{
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 100%;
    width: calc(100% - 16px);
    margin-bottom: 8px;
    height: calc(calc(calc(76vw * 9)/16) + 44px);
}
.albumGroup a:first-child{
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 100%;
    width: calc(100% - 16px);
    margin-bottom: 8px;
    height: calc(calc(calc(76vw * 9)/16) + 44px);
}
.albumGroup h5{
    width: calc(100% - 16px);
    height: calc(calc(calc(25vw * 9)/16) + 44px);
    display: grid;
    place-items: center;
    background-color: #060606b8;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: -6px;
    grid-column: 3/-1;
    grid-row: 2/-1;
    color: var(--font-color);
    z-index: 100000;
}
.albumGroup div img{
    margin: -8px;
    width: auto;
    max-width: 100%;
    height: inherit;
    max-height: 100%;
}
.albumGroup a iframe{
    margin: -8px;
    width: 100%;
    margin-top: 0px;
    aspect-ratio: 16/9;
}
@media screen and (max-width:950px) {
    .albumGroup{
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    .albumGroup *:nth-child(4){
        grid-column: 2/-1;
        grid-row: 2/-1;
    }
    .albumGroup h5{
        grid-column: 2/-1;
        grid-row: 2/-1;
    }
    .albumGroup *:first-child{
        grid-row: 1/2 !important;
        grid-column: 1/2 !important;
    }
    .albumGroup *{
        height: calc(calc(calc(20vw * 9)/16) + 54px) !important;
    }
    .albumGroup * iframe{
        height: inherit;
        margin-top: 0px;
        max-height: calc(100% - 26px);
    }
    .albumGroup a h4{
        margin-top: -22px;
    }
}
@media screen and (max-width:700px) {
    .albumGroup.default{
        width: 80%;
        grid-template-rows: 1fr 1fr;
        transform: translateX(0px);
    }
    .albumGroup{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    .albumGroup div{
        grid-column: 1/-1;
        height: calc(calc(calc(60vw * 9)/16) + 24px) !important;
        margin: 8px 0px;
    }
    .albumGroup *:first-child{
        grid-column: 1/-1;
        height: calc(calc(calc(60vw * 9)/16) + 24px) !important;
    }
    .albumGroup *:nth-child(4){
        grid-column: 1/-1;
        grid-row: 4/-1;
    }
    .albumGroup a{
        grid-column: 1/-1;
        height: calc(calc(calc(60vw * 9)/16) + 24px) !important;
        margin: 8px 0px;
    }
    .albumGroup h5{
        grid-column: 1/-1;
        grid-row: 4/-1;
        height: calc(calc(calc(60vw * 9)/16) + 24px) !important;
        margin: 8px 0px;
        transform: translateY(calc(100% + 16px));
    }
    .galleryHeader{
        color: black;
        float: left;
        width: 100%;
        display: block;
        margin-left: 20vw;
        font-family: var(--font-heavy-italic);
        font-size: 4vh;
    
    }
}