.eventContainer{
    grid-row: 1/-1;
    width: 100%;
    height: 100%;
    grid-auto-flow: row;
    display: grid;
    place-items: start center;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #0e0e0d;
    grid-template-rows: max-content;
    row-gap: 100px;
}
.eventContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 4px;
}
.eventContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.eventContainer .event-head{
    width: 100vw;
    max-width: 100vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}
.eventContainer .albumSection{
    width: 78vw;
}
.eventContainer .event-head .event-cover{
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    grid-column: 1/-1;
    grid-row: 1/-1;
    object-fit: cover;
}
.eventContainer .coverOverlay{
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    grid-column: 1/-1;
    grid-row: 1/-1;
    object-fit: cover;
    background: linear-gradient(0deg, rgb(14 14 13 / 99%) 0%, rgba(255,255,255,0) 30%);
    z-index: 1;
}
.eventContainer .event-head .event-cover.cut{
    object-fit: cover;
}
.eventContainer .event-head .event-cover.stretched{
    object-fit: auto;
}
.eventContainer .event-head .event-name{
    font-family: var(--font-heavy-italic);
    font-weight: 1000;
    word-spacing: 100vw;
    margin-left: 12vw;
    margin-top: 20vh;
    font-size: calc(100vw / 14);
    grid-column: 1/-1;
    grid-row: 1/-1;
    z-index: 10;
    word-break: keep-all;
}
.event-body{
    display: grid;
    place-items: center start;
    width: 78%;
    margin-left: 2vw;
    margin-top: -17vh;
    z-index: 10;
}
.event-body .event-description{
    max-width: 78vw;
    word-break: keep-all;
    text-align: justify;
    /* line-height: 27px; */
    font-size: 1.466em;
}
.event-body .event-register-button{
    height: 60px;
    width: 180px;
    color: white;
    background-color: var(--active-color);
    border: none;
    outline: none;
    font-family: var(--font-medium);
    font-weight: 800;
    font-size: 22px;
    margin-top: 50px;
    cursor: pointer;
}
.eventContainer .footer{
    text-align: center;
    place-items: center;
    display: grid;
}
@media (max-width:950px){
    .event-name{
        font-size: calc(100vw / 10) !important;
    }
}
@media (max-width:550px){
    .event-body .event-description{
        text-align: left;
    }
    .event-name{
        font-size: calc(100vw / 7) !important;
    }
}