.allAcademiesContainer{
    grid-column: 1/-1;
    grid-row: 1/-1;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-auto-flow: row;
    display: grid;
    place-items: start;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    color: #000;
    font-weight: 600;
}
.allAcademiesContainer .sliderWrapper{
    display: grid;
    place-items: center;
    justify-content: center;
    width: 100vw;
    height: auto;
    grid-auto-flow: column;
    grid-template-columns: 5vw max-content 5vw;
    grid-template-rows: 1fr;
    grid-row: 1/-1;
}
.allAcademiesContainer .sliderWrapper .nextBtn{
    grid-row: 1/-1;
    grid-column: 3/-1;
    fill: #fff;
    width: 20px;
    cursor: pointer;
    rotate: -90deg;
}
.allAcademiesContainer .sliderWrapper .prevBtn{
    grid-row: 1/-1;
    grid-column: 1/2;
    fill: #fff;
    width: 20px;
    cursor: pointer;
    rotate:  90deg;
    z-index: 1000;
}
.allAcademiesContainer .sliderWrapper .sloganContainer h2{
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    border-radius: 6px;
    line-height: calc(100vw / 38);
    grid-column: 1/-1;
    grid-row: 1/-1;
    z-index: 10;
    color: white;
    font-family: var(--font-condensed-heavy-italic);
    font-size: calc(100vw / 33);
    transform: translate(10vw, -5vw);
    place-items: end start;
    transform-origin: bottom;
}
.allAcademiesContainer .sloganContainer{
    grid-row: 1/-1;
    grid-column: 1/-1;
    place-items: end start;
    display: flex;
    align-items: end start;
    overflow: hidden;
    place-self: center;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
}
.allAcademiesContainer .imageSlider{
    grid-row: 1/-1;
    grid-column: 1/-1;
    place-items: end start;
    display: flex;
    align-items: center;
    overflow: hidden;
    place-self: center;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
}
.allAcademiesContainer .pointsContainer{
    grid-row: 1/-1;
    grid-column: 2/3;
    display: grid;
    grid-auto-flow: column;
    place-self: end center;
    z-index: 1000;
    width: 72vw;
    height: 30px;
    justify-content: center;
    column-gap: 7px;
}
.allAcademiesContainer .pointsContainer span{
    max-width: 10px;
    width: 2vw;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: rgba(37, 37, 37, 0.788);
    cursor: pointer;
    transition: 0.8s;
}
.allAcademiesContainer .pointsContainer span.current{
    background-color: rgba(143, 143, 143, 0.788);
}
.imageSlider .imagesContainer{
    display: flex;
    transition: transform 300ms ease-in-out;
}
.allAcademiesContainer .imageSlider img{
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    width: 100vw;
    aspect-ratio: 16/9;
    border-radius: 6px;
    grid-column: 1/-1;
    grid-row: 1/-1;
}
.allAcademiesContainer #academies{
    width: 100%;
    height: max-content;
    max-height: max-content;
    display: grid;
    place-items: center;
    grid-auto-flow: row;
    grid-template-rows: max-content max-content;
}
.allAcademiesContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 8px;
}
.allAcademiesContainers::-webkit-scrollbar-button{
    background-color: transparent;
}
.allAcademiesContainer::-webkit-scrollbar-corner{
    background-color: transparent;
    height: 90px;
}
.allAcademiesContainer::-webkit-scrollbar-track{
    background-color: transparent;
    height: 90px;
}
.allAcademiesContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.allAcademiesContainer .academiesWelcome{
    grid-column: 1/-1;
    grid-row: 1/2;
    font-family: var(--font-condensed-heavy-italic);
    text-align: start;
    place-self: center start;
    width: 78vw;
    word-spacing: 5px;
    font-size: 70px;
    margin: 5vh 0px 5vh 12vw;
    letter-spacing: -1px;
}
.allAcademiesContainer #academies .sports{
    width: 78vw;
    height: fit-content;
    place-items: center start;
    display: grid;
    place-self: center;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(auto,1fr);
}
.allAcademiesContainer #academies .sportCard{
    width: 95%;
    display: grid;
    margin-bottom: 50px;
    text-decoration: none;
    color: var(--font-color);
}
.allAcademiesContainer #academies .sportCard h2{
    height: max-content;
    color: #000;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    word-break: keep-all;
    font-family: var(--font-condensed-bold-italic);
    transform-origin: bottom;
}
.allAcademiesContainer #academies .sportCard p{
    height: max-content;
    color: #1a1a1a;
    text-decoration: none;
    font-size: 20px;
    word-break: keep-all;
    margin-bottom: 10px;
}
.allAcademiesContainer #academies .sportCard .linkSect{
    height: 30px;
    color: var(--active-color);
    text-decoration: none;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
    word-break: keep-all;
}
.allAcademiesContainer .footer{
    display: grid;
    width: 100vw;
    height: max-content;
    place-items: center;
    text-align: center;
    height: 40vh;
    grid-template-rows: max-content max-content max-content;
    margin-top: 20vh;
}
.footer img{
    width: 70vw;
    max-width: 300px;
}
.footer h2{
    font-family: var(--font-heavy-italic);
    font-size: 30px;
    max-width: 70vw;
}
.footer .media-in-footer{
    width: min-content;
    place-self: center;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
}
.footer .media-in-footer .media{
    width: 30px;
    padding: 8px;
    filter: none !important;
}
.footer.light .media-in-footer .media img{
    width: 30px;
    filter: invert(1);
}
.academiesAlbumSection{
    display: grid;
    place-items: center;
    width: 78vw;
    place-self: center;
}
.academiesAlbumSection h2{
    place-self: start;
    font-family: var(--font-condensed-heavy-italic);
    font-size: 50px;
    margin-bottom: 5px;
    font-weight: 900;
    transform-origin: top;
}
@media screen and (max-width: 1000px) {
    .allAcademiesButton{
        display: none !important;
    }
}
@media screen and (max-width: 1050px) {
    .allAcademiesContainer #academies .sports{
        grid-template-columns: repeat(2,1fr);
    }
    .allAcademiesContainer #academies .sports .sportCard.odd{
        grid-column: 1/-1;
    }
    .allAcademiesContainer #academies .academiesWelcome{
        font-size: 50px;
    }
    .allAcademiesContainer .sliderWrapper h2{
        font-size: 25px;
        max-width: 70vw;
    }
}
@media screen and (max-width: 700px) {
    .allAcademiesContainer #academies .sports{
        grid-template-columns: repeat(1,1fr);
    }
    .allAcademiesContainer #academies .academiesWelcome{
        font-size: 25px;
    }
    .allAcademiesContainer #academies .sports .sportCard h2{
        font-size: 22px;
    }
    .allAcademiesContainer #academies .sports .sportCard p{
        font-size: 16px;
    }
    .allAcademiesContainer #academies .sports .sportCard{
        width: 70%;
        height: fit-content;
    }
    .allAcademiesContainer #academies .sports .sportCard img{
        width: 100%;
        height: auto;
    }
    .allAcademiesContainer .sliderWrapper h2{
        font-size: 15px;
        margin: 0px 0px 20px 20px;
    }
}