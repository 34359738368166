.LifeSportClubVideoPlayer{
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    position: relative;
    place-items: start;
    touch-action: none;
    background-color: #242424;
    background-repeat: no-repeat;
    overflow: hidden;
    place-items: center;
}
.player-back-button{
    grid-row: 1/-1;
    grid-column: 1/-1;
    place-self: start;
    z-index: 10;
    width: 35px;
    height: 35px;
    margin-top: 110px;
    margin-left: 2vw;
    cursor: pointer;
}
.LifeSportClubVideoPlayer iframe{
    max-width: 85vw;
    max-height: calc(100vh - calc(90px + 8vw));
    aspect-ratio: 16/9;
    border: none;
    background-color: black;
    grid-column: 1/-1;
    grid-row: 1/-1;
    margin-top: calc(120px + 4vw);
}