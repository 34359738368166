.navBar{
    grid-row: 1/2;
    grid-column: 1/-1;
    z-index: 100000;
    height: 100px;
    display: grid;
    background-color: transparent;
    place-items: center;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr minmax(min-content,min-content);
    width: calc(100vw - 10px);
    padding-top: 20px;
    font-family: var(--font-bold);
    text-transform: capitalize;
}
.navBar::before{
    width: 100%;
    height: 170px;
    position: absolute;
    content: " ";
    top: 0;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .7) 50%, rgba(0, 0, 0, 0) 100%);
}

.navBar.light::before{
    width: 100%;
    height: 170px;
    position: absolute;
    content: " ";
    top: 0;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, 0) 100%);
}
.decoratelessLink{
    text-decoration: none;
    z-index: 10000000;
}
.logo{
    max-height: 80px;
    width: auto;
    padding: 0px 0px 0px 80px;
    margin-top: 25px;
    color: var(--font-color);
}

.hamburger{
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    z-index: 10000000;
    height: auto;
    width: auto;
    place-self: center start;
    padding: 0px 40px;
    margin-top: 8px;
}

.hamburger .menuItem{
    padding: 0px 30px;
    transition: 0.4s;
    text-decoration: none;
    font-size: 16px;
    word-break: keep-all;
    width: 170px !important;
    margin: -0px 5px !important;
    height: 45px !important;
    color: var(--font-color);
    display: grid;
    font-weight: 100;
    font-family: var(--font-bold);
    place-items: center;
    border-radius: 14px;
    background-color: transparent;
}
.navBar.light .menuItem{
    color: #000;
    font-family: var(--font-bold);
    font-weight: 600px;
}
.navBar.light .menuItem.active{
    background-color: #e5e5e5;
}
.navBar.light .menuItem:hover{
    background-color: #e5e5e5;
}
.hamburger .menuItem.academies{
    padding: 0 5px;
    margin-left: 40px !important;
}
.hamburger .menuItem[href="/about"]{
    width: 125px;
}
.overlayMenu .menuItem{
    padding: 20px;
    transition: 0.4s;
    text-decoration: none;
    place-self: center start;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: var(--font-color);
}
.overlayMenu .insiderMediaRow{
    width: min-content;
    place-self: start;
    display: grid;
    grid-auto-flow: column;
    place-items: center start;
    grid-template-columns: auto auto auto;
    padding: 15px 20px;
    column-gap: 30%;
}
.overlayMenu .overlayClose{
    margin: 15px;
    transition: 0.4s;
    place-self: center end;
    width: 22px;
    height: 22px;
    cursor: pointer;
}
.menuItem.active{
    background-color: rgba(0, 0, 0, .9);
}
.insiderItem{
    transition: 0.4s;
}
.insiderItem.active{
    background-color: rgba(0, 0, 0, .9);
}
.overlayMenu .menuItem:hover{
    color: var(--active-color);
    transition: 0.4s;
}
.menuItem.mainLink:hover{
    background-color: rgba(0, 0, 0, .9);
}
.overlayClose:hover{
    color: var(--active-color-keen);
    transition: 0.4s;
}

svg.btn{
    transition: 0.4s;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    z-index: 10;
}
svg.btn:hover{
    fill: var(--active-color);
    transition: 0.4s;
}
.light svg.btn{
    fill: #000;
    transition: 0.4s;
}

.overlayMenu{
    grid-row: 2/-1;
    grid-column: 1/-1;
    width: 100%;
    place-self: end;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, .3);
    display: none;
}
.overlayMenu .insideMenu{
    height: 100%;
    background-color: rgb(48, 48, 48);
    place-self: start;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(7,min-content);
    place-items: start center;
    width: 250px;
    transition: 0.6s;
}
.overlayMenu.show .insideMenu{
    width: 250px;
}
.lang{
    width: 40px;
    height: 40px;
    margin: 0 15px;
    place-self: center;
    cursor: pointer;
}
.miniMenuBtn{
    place-self: center end;
    margin: 0 20px;
    font-size: 18px;
    font-weight: bold;
    transition: .6s;
    cursor: pointer;
}
.miniMenuBtn:hover{
    color: var(--active-color);
}
.miniMenuBtn.active{
    transform: rotate(90deg);
    color: var(--active-color);
}
.miniMenuBtn.active.invert{
    transform: rotate(-90deg);
    color: var(--active-color);
}
.languages{
    grid-column: 1/-1;
    grid-row: 2/-1;
    width: 150px;
    height: 200px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    place-self: start end;
    margin: 15px 30px;
    background-color: rgba(0, 0, 0, .9);
}
.languages.hide{
    opacity: 0;
    transition: 0.6s;
    z-index: -10000000;
}
.languages.show{
    opacity: 1;
    transition: 0.6s;
    z-index: 10000000;
}
.aboutMenu{
    grid-column: 1/-1;
    grid-row: 2/-1;
    width: 170px;
    height: 230px;
    display: grid;
    place-items: center;
    grid-template-rows: 45px repeat(4,1fr);
    border-radius: 12px;
    place-self: start;
    background-color: rgba(0, 0, 0, .9);
    transform: translate(286px,-44px);
}
.aboutMenu a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0 12px;
    font-size: 16px;
    transition: 0.4s;
    font-family: var(--font-medium);
}
.aboutMenu a:hover{
    color: var(--active-color) !important;
}
.aboutMenu.hide{
    opacity: 0;
    transition: 0.4s;
    z-index: -10000000;
}
.aboutMenu.show{
    opacity: 1;
    transition: opacity 0.4s;
    font-size: 16px;
    z-index: 100000;
}
.aboutMenu .menuItem{
    color: var(--active-color-keen);
    place-self: center;
    display: grid;
    place-items: center;
    margin-top: 0px;
    margin-left: 12px;
    font-family: var(--font-bold);
}
.communityMenu .menuItem{
    color: var(--active-color-keen);
    place-self: center;
    display: grid;
    place-items: center;
    margin-top: 12px;
    font-weight: 900;
    margin-left: 11px;
    font-family: var(--font-bold);
}
.communityMenu{
    grid-column: 1/-1;
    grid-row: 2/-1;
    z-index: 100000;
    width: 170px;
    height: 230px;
    display: grid;
    place-items: center;
    grid-template-rows: 30px repeat(4,1fr);
    border-radius: 12px;
    place-self: start;
    background-color: rgba(0, 0, 0, .9);
    margin: 2px 0px 0px 0px;
    transform: translate(466px,-44px);
}
.light.aboutMenu{
    background-color: #e5e5e5;
}
.light.communityMenu{
    background-color: #e5e5e5;
}
.light.aboutMenu a{
    color: #000;
}
.light.communityMenu a{
    color: #000;
}
.light svg{
    fill: black;
}
.communityMenu a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0 12px;
    font-size: 16px;
    transition: 0.4s;
    font-family: var(--font-medium);
}
.communityMenu a:hover{
    color: var(--active-color);
}
.communityMenu.hide{
    opacity: 0;
    transition: 0.4s;
    z-index: -10000000;
}
.communityMenu.show{
    opacity: 1;
    transition: opacity 0.4s;
    z-index: 10000000;
}
.miniAbout{
    width: 150px;
    height: 140px;
    margin: 0 30px;
    display: grid;
    grid-auto-flow: row;
    place-items: center start;
}
.miniAbout a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0 12px;
    font-size: 0px;
    transition: 0.4s;
}
.miniAbout a:hover{
    color: var(--active-color);
}
.miniAbout.hide{
    height: 0;
    opacity: 0;
    transition: 0.6s;
}
.miniAbout.show{
    height: 160px;
    opacity: 1;
    transition: 0.6s;
}
.miniAbout.show a{
    font-size: 13px;
}
.miniCommunity{
    width: 150px;
    height: 160px;
    margin: 0 30px;
    display: grid;
    grid-auto-flow: row;
    place-items: center start;
}
.miniCommunity a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0 12px;
    font-size: 0px;
    transition: 0.4s;
}
.miniCommunity a:hover{
    color: var(--active-color);
}
.miniCommunity.hide{
    height: 0;
    opacity: 0;
    transition: 0.6s;
}
.miniCommunity.show{
    height: 160px;
    opacity: 1;
    transition: 0.6s;
}
.miniCommunity.show a{
    font-size: 13px;
}
.languages h4{
    cursor: pointer;
    transition: 0.6s;
    font-size: 17px;
    font-weight: 600;
}
.languages h4:hover{
    color: var(--active-color-keen);
    transition: 0.6s;
}
.overlayMenu.hide{
    opacity: 0;
    transition: 0.4s;
    z-index: -1000000;
}
.overlayMenu.show{
    opacity: 1;
    transition: 0.4s;
    z-index: 1000000;
}
.media{
    margin: 10px;
    margin-top: 18px;
    z-index: 10000000;
}
.light .media img{
    filter: invert(1);
}
.mediaLink{
    text-decoration: none;
    color: var(--active-color);
    text-align: center;
    word-break: keep-all;
    width:200px;
    font-size: 18px;
    stroke-width: 2px;
    stroke-opacity: 1;
    font-weight: 900;
    transition: 0.6s;
    font-family: var(--font-bold);
    height: 50px;
    border-radius: 14px;
    display: grid;
    place-items: center;
    z-index: 10000000;
}
.light .mediaLink{
    filter: invert(0);
    color: var(--active-color) !important;
}
.mediaLink:hover{
    background-color: rgba(0, 0, 0, .9);
}
.linkedIn{
    margin-right: 50px;
}
@media screen and (max-width: 1450px){
    .logo{
        height: 50px;
        margin-top: 12px;
    }
    .aboutMenu{
        transform: translate(226px,-59px);
    }
    .communityMenu{
        transform: translate(406px,-59px);
    }
}
@media screen and (max-width: 1350px){
    .hamburger{
        width: 0px;
        padding: 0px;
        margin: 0px;
        height: 0px;
    }
    .media{
        display: none;
    }
    .mediaLink{
        display: grid;
    }
    .hamburger .menuItem{
        display: none;
    }
    .btn{
        display: grid !important;
    }
    .overlayMenu{
        display: grid;
    }
    .logo{
        height: 50px;
        margin-top: 12px;
    }
    svg.btn{
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-top: 5px;
    }
    .media{
        margin: 7px;
    }
}
@media screen and (max-width: 620px) {
    .mediaLink{
        font-size:15px !important;
        width: 150px;
    }
    .linkedIn{
        margin-right: 20px;
    }
}
@media screen and (max-width: 540px) {
    .logo{
        margin-left: -10vw;
    }
    .media img{
        width: 20px
    }
}