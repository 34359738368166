.notFoundContainer{
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 100%;
    height: 100vh;
    place-items: center;
    display: grid;
    background-image: url('./../../comingSoon.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.notFoundContainer h2{
    place-self: end center;
    font-size: 35px;
    color: #fff;
    margin-bottom: 130px;
}
.aboveAlll{
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: absolute;
    display: grid;
    place-items: center start;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}