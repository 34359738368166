.forms{
    display: grid;
    grid-column: 1/-1;
    width: 100%;
    height: 100vh;
    grid-template-rows: repeat(auto, auto);
    place-self: start center;
    place-items: start center;
    text-align: center;
    overflow-x: hidden;
}
.forms::-webkit-scrollbar{
    background-color: transparent;
    width: 4px;
}
.forms::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.forms.withSpace{
    padding-top: calc(90px + 10vh);
}
.forms form{
    display: grid;
    grid-column: 1/-1;
    width: 100vw;
    left: 0px;
    grid-template-rows: repeat(auto, auto);
    place-self: start center;
    place-items: start center;
    text-align: center;
}
.forms form .applogo{
    height: auto !important;
    max-height: 150px;
    max-width: 300px;
}
.forms form .sammary{
    font-size: 20px;
    word-break: keep-all;
    width: 70%;
    margin-top: 25px;
}
.forms form .inputsFieldsAround{
    margin-top: 25px;
    width: 70%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 15px;
}
.forms form .codeFormButton{
    margin-top: 40px;
    width: 20%;
    min-width: 200px;
}
.forms form .inputAround{
    background-color: transparent;
    width: 100%;
    height: 54px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    z-index: 1;
    transition: 0.6s;
    border: 0px solid transparent;
}
.forms form .dropdownAround{
    background-color: transparent;
    width: 100%;
    height: 54px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    z-index: 1;
    transition: 0.6s;
    border: 0px solid transparent;
}
.forms form .dropdownAround select{
    background-color: rgba(150, 150, 150, 0.5);
    border: none;
    outline: none;
    height: 50px;
    z-index: 1;
    width: calc(100% - 4px);
    border-radius: 12px;
    padding: 0 12px;
    font-size: 17px;
    grid-column: 1/-1;
    grid-row: 1/-1;
    color: #fff;
}
.forms form .dropdownAround option{
    background-color: rgb(110, 110, 110);
    font-size: 19px;
}

.forms .formWrap{
    margin-top: 25px;
    place-self: center;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(auto, auto);
    place-self: start center;
    place-items: start center;
    text-align: center;
    font-weight: 600;
}

.forms .formWrap.inlineForm{
    grid-auto-flow: column;
    grid-template-columns: 1fr 130px;
    width: 70%;
}
.forms form .inlineForm .codeInputAround{
    width: 100%;
}
.forms form .codeInputAround{
    width: 70%;
}
.forms form .inputAround input{
    background-color: rgba(150, 150, 150, 0.5);
    border: none;
    outline: none;
    height: 50px;
    z-index: 1;
    width: calc(100% - 4px);
    border-radius: 12px;
    padding: 0 12px;
    font-size: 17px;
    grid-column: 1/-1;
    grid-row: 1/-1;
    color: #fff;
}

.forms form .inputAround input:-webkit-autofill,
.forms form .inputAround input:-webkit-autofill:hover, 
.forms form .inputAround input:-webkit-autofill:focus, 
.forms form .inputAround input:-webkit-autofill:active{
    -webkit-transition-delay: 2147483647s;
}

.forms form .sectionHeader{
    font-family: var(--font-medium);
    margin-top: 25px;
}
.forms form button{
    background-color: var(--active-color);
    border: none;
    outline: none;
    place-self: center;
    height: 50px;
    z-index: 10;
    width: 120px;
    border-radius: 12px;
    text-align: center;
    font-size: 24px;
    color: var(--font-color);
    font-family: var(--font-medium);
    font-weight: 800;
    cursor: pointer;
    transition: 0.6s;
}
.forms form:last-child button{
    margin: 20px 0 80px 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px #242424 inset !important;
    -webkit-text-fill-color: #e3e3e3 !important;
}
.forms form .inputAround button:hover{
    color: var(--active-color-keen);
    transition: 0.6s;
}
.forms form .inputAround.wrong input{
    border: 1px solid rgba(226, 85, 85, 0.979);
}
.forms form .inputAround input:focus + .coolLabel h6{
    color: var(--active-color);
    transition: 0.6s;
}
.forms form .areaAround textarea{
    background-color: rgba(150, 150, 150, 0.5);
    border: none;
    outline: none;
    height: 200px !important;
    resize: none;
    z-index: 1;
    width: calc(100%) !important;
    border-radius: 12px;
    padding: 12px 12px;
    font-size: 17px;
    grid-column: 1/-1;
    grid-row: 1/-1;
    color: #ffffff;
    font-family: var(--font-medium);
}
.forms form .areaAround{
    background-color: transparent;
    width: 70%;
    height: 200px;
    margin-top: 25px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    z-index: 1;
    border: 0px solid transparent;
    transition: 0.6s;
}
.forms form .areaAround.wrong textarea{
    border: 1px solid rgba(226, 85, 85, 0.979);
}
.forms form .areaAround textarea::placeholder{
    color: var(--font-color);
    opacity: 1;
    font-size: 17px;
}
.forms form .inputAround input::placeholder{
    color: var(--font-color);
    opacity: .9;
    font-size: 17px;
}

.countryCode{
    display: grid;
    grid-auto-flow: column;
    place-items: center start;
    column-gap: 5px;
    text-align: center;
    height: 50px;
    transform: translateY(-100%);
    place-self: center start;
    font-size: 14px;
    margin-bottom: 3px;
}

.countryCode img{
    height: 15px;
    width: 20px;
    margin-left: 12px;
    margin-top: 3px;
}
.refinput.mobile-number{
    padding-left: 67px !important;
}

@media (max-width:875px) {
    .forms form .inputsFieldsAround{
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width:750px){
    .forms form{
        place-items: start center;
    }
    .forms form .sammary{
        width: 80%;
    }
    .forms form .areaAround{
        width: 80%;
    }
    .forms form .inputsFieldsAround{
        width: 80%;
    }
    .forms form .codeInputAround{
        width: 80%;
    }
}
@media screen and (max-width:400px){
    .forms form{
        margin-bottom: 20px !important;
    }
    .forms form button{
        margin-bottom: 20px !important;
    }
    .forms form .sammary{
        width: 90%;
    }
    .forms form .inputsFieldsAround input::placeholder{
        font-size: 13px;
    }
    .forms form .codeInputAround{
        width: 80%;
    }
    .forms form .codeInputAround input::placeholder{
        font-size: 13px;
    }
    .forms form .codeInputAround button{
        font-size: 16px;
    }
    .forms form .areaAround textArea::placeholder{
        font-size: 13px;
    }
}

.formsLoader{
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1000000;
}

.formsLoader .formsLoaderWrapper{
    display: grid;
    width: fit-content;
    height: fit-content;
    place-self: center;
    place-items: center;
    row-gap: 100px;
}

.formsLoader h2{
    font-size: 25px;
    max-width: 80vw;
    word-break: keep-all;
}

.formsLoader.hidden{
    display: none;
}
.formsLoader.show{
    display: grid;
}

@keyframes ldio-tp6laxxntc {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%,-50%) rotate(360deg);
    }
}
.ldio-tp6laxxntc div {
position: absolute;
width: 120px;
height: 120px;
border: 20px solid #44d62c;
border-top-color: transparent;
border-radius: 50%;
}
.ldio-tp6laxxntc div {
animation: ldio-tp6laxxntc 1s linear infinite;
top: 100px;
left: 100px
}
.loadingio-spinner-rolling-jr4girhj6eh {
width: 200px;
height: 200px;
display: inline-block;
overflow: hidden;
background: none;
}
.ldio-tp6laxxntc {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(1);
backface-visibility: hidden;
transform-origin: 0 0;
}
.ldio-tp6laxxntc div {
    box-sizing: content-box;
}
