.academyContainer{
    grid-row: 1/-1;
    width: 100vw;
    height: 100%;
    grid-auto-flow: row;
    display: grid;
    grid-template-columns: 1fr;
    place-items: start center;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
    grid-template-rows: max-content;
    row-gap: 100px;
    left: 0;
    top: 0;
    font-weight: 600;
}
.academyContainer::-webkit-scrollbar{
    background-color: transparent;
    width: 4px;
}
.academyContainer::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.academyContainer .academy-head{
    display: grid;
    place-items: center start;
    width: 100%;
    color: #0e0e0d;
    height: 100%;
}
.academyContainer .academy-head .academy-cover{
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    grid-column: 1/-1;
    grid-row: 1/-1;
    object-fit: cover;
}
.academyContainer .academy-head .academy-name{
    display: grid;
    place-self: start;
    place-items: center;
    grid-column: 1/-1;
    grid-row: 1/-1;
    width: 100%;
    transform: translate(0, calc(50vh - 50%));
}
.academyContainer .academy-head .academy-name h2{
    font-family: var(--font-heavy-italic);
    font-weight: 1000;
    text-align: center;
    display: grid;
    color: #000;
    font-size: calc(100vw / 8);
}
.academy-body{
    display: grid;
    place-items: center start;
    width: 78%;
    margin-left: 2%;
    color: #0e0e0d;
}
.academy-body .academy-description{
    text-align: justify;
    font-size: 20px;
    /* width: 90%; */
}
.academy-body .academy-register-button{
    height: 55px;
    width: 180px;
    color: white;
    background-color: var(--active-color);
    border: none;
    outline: none;
    font-family: var(--font-medium);
    font-weight: 800;
    font-size: 20px;
    margin-top: 50px;
    cursor: pointer;
}
.academy-coach{
    color: #0e0e0d;
    display: grid;
    grid-auto-flow: column;
    place-items: start;
    margin: 25px 12vw;
    max-width: 78vw;
    place-self: center start;
}
.academy-coach .coach-photo{
    width: 200px;
    height: 200px;
}
.academy-coach .coach-name{
    color: var(--active-color);
    margin-left: 25px;
    margin-top: -10px;
    font-size: 30px;
    font-family: var(--font-bold-italic);
    font-weight: 200;
    transform: scale(1,1.4);
    letter-spacing: 0px;
}
.academy-coach .coach-summary{
    margin-left: 30px;
    margin-top: 5px;
    font-size: 20px;
    text-align: justify;
}
.footer{
    color: #0e0e0d;
    padding-bottom: 13vh;
}
.academyContainer .footer.light{
    text-align: center;
    place-items: center;
    display: grid;
}
@media screen and (max-width: 700px) {
    .academy-coach{
        grid-auto-flow: row;
        place-items: start center;
        width: 78vw;
        place-self: start center;
        row-gap: 25px;
        margin: 0;
        text-align: center;
        word-break: keep-all;
    }
    .academy-coach .coach-name{
        margin: 0;
        margin-bottom: 15px;
    }
    .academy-coach .coach-summary{
        margin: 0;
    }
}
@media (max-width:550px){
    .academy-body .academy-description{
        text-align: left;
    }
    .academy-coach .coach-summary{
        text-align: left;
    }
}