.daApp{
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: grid;
    position: relative;
    place-items: start;
    touch-action: pan-y;
    background-color: #242424;
    overflow: hidden;
    overflow-y: scroll;
}
.daApp::-webkit-scrollbar{
    background-color: transparent;
    width: 4px;
}
.daApp::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: var(--dim-grey);
}
.daApp .formsWrapper{
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-column: 1/-1;
    display: grid;
    position: relative;
    place-items: start;
    touch-action: pan-y;
    margin-top: 65px;
}
iframe.youtubeEmbed{
    grid-row: 1/2;
    grid-column: 1/-1;
    z-index: 10;
    width: 83vw;
    border-radius: 8px;
    aspect-ratio: 16/9;
    margin-top: calc(90px + 6vh);
    place-self: start center;
    margin-bottom: 50px;
    margin-left: 0vw;
}
.customLink{
    font-size: 16px;
    text-decoration: none;
    color: var(--active-color);
    transition: 0.6s;
}
.customLink:hover{
    text-decoration: none;
    color: var(--active-color-keen);
    transition: 0.6s;
}
.daApp .forms{
    grid-row: 2/3;
    height: fit-content;
    grid-template-rows: auto auto;
    min-height: 100vh;
    position: relative;
}
.forms .contactForm{
    width: 100vw;
    position: relative;
}
.contactForm::before{
    content: " ";
    width: 100vw;
    height: 100vh;
    bottom: 0;
    position: absolute;
    background-image: url('./../../membership.png');
    background-position: center 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.daApp .footer{
    grid-row: 3/-1;
    display: grid;
    width: 100vw;
    height: max-content;
    place-items: center;
    text-align: center;
    height: auto;
    grid-template-rows: max-content max-content max-content;
    margin-top: 2vh;
    height: fit-content;
    margin-bottom: 20vh;
}

@media screen and (max-width: 650px) {
    .contactForm::before {
        background-size: 140% 100%;
    }
}

@media screen and (max-width: 500px) {
    .contactForm::before {
        background-size: 160% 100%;
    }
}